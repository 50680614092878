// libraries
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
// apollo components
import ArrowDown from "apollo-react-icons/ArrowDown";
import ArrowRight from "apollo-react-icons/ArrowRight";
import EllipsisVertical from "apollo-react-icons/EllipsisVertical";
import IconButton from "apollo-react/components/IconButton";
import Menu from "apollo-react/components/Menu";
import MenuItem from "apollo-react/components/MenuItem";
import Status from "apollo-react/components/Status";
import StatusDotSolid from "apollo-react-icons/StatusDotSolid";
import Table from "apollo-react/components/Table";
import Tag from "apollo-react/components/Tag";
import Tooltip from "apollo-react/components/Tooltip";
import Typography from "apollo-react/components/Typography";
// components
import { ReactComponent as RoundPlusSvg } from "../../components/Icons/roundplus.svg";
import { ReactComponent as PackageIcon } from "../../components/Icons/datapackage.svg";
// helpers
import { MessageContext } from "../../components/Providers/MessageProvider";
import { updateDSState } from "../../store/actions/DataFlowAction";
import {
  resetSqlColumns,
  updateDSStatus,
} from "../../store/actions/DataSetsAction";
import {
  setExpanded,
  updateHeaderCount,
} from "../../store/actions/DashboardAction";
import {
  getPackagesList,
  redirectToDataSet,
  selectDataPackage,
  updatePanel,
} from "../../store/actions/DataPackageAction";
import {
  Categories,
  Features,
  useStudyPermission,
} from "../../components/Common/usePermission";
import {
  deleteDraftPackage,
  deletePackage,
  toggleDatasetsStatus,
  toggleDraftDatasetsStatus,
  updateDraftPackageStatus,
  updatePackageStatus,
} from "../../services/ApiServices";
// styles
import "./DataPackages.scss";
import { BASIC_DATA_CONNECTOR_DA } from "./DataPackagesHelper";

const ExpandCell = ({ row: { handleToggleRow, expanded, datapackageid } }) => {
  return (
    <div style={{ width: 12, marginLeft: "1px" }}>
      <Tooltip title={expanded ? "Collapse" : "Expand"} disableFocusListener>
        <IconButton
          id="expand"
          size="small"
          onClick={() => handleToggleRow(datapackageid)}
        >
          {expanded ? <ArrowDown /> : <ArrowRight />}
        </IconButton>
      </Tooltip>
    </div>
  );
};

const NameCustomCell = ({ row, column: { accessor } }) => {
  const title = row?.nopackageconfig === 1 ? "No Package" : row[accessor];
  const dispatch = useDispatch();
  const history = useHistory();

  const editAction = () => {
    if (row?.datapackageid) {
      dispatch(selectDataPackage(row));
      history.push("/dashboard/data-packages");
    }
  };

  return (
    <div className="flex package-name-td">
      <PackageIcon style={{ width: 15, margin: "0px 10px" }} />
      {title.length > 20 ? (
        <Tooltip title={title} placement="top">
          <span
            role="button"
            tabIndex={0}
            className="b-font package-name"
            onClick={editAction}
            aria-hidden="true"
          >
            {title}
          </span>
        </Tooltip>
      ) : (
        <span
          role="button"
          tabIndex={0}
          className="b-font package-name"
          onClick={editAction}
          aria-hidden="true"
        >
          {title}
        </span>
      )}
    </div>
  );
};

const StatusCustomCell = ({ row, column: { accessor } }) => {
  const active = Number(row[accessor]);
  return (
    <div className="flex">
      {active === 1 ? (
        <Tag label="Active" variant="green" />
      ) : (
        <Tag label="Inactive" variant="grey" />
      )}
    </div>
  );
};

const PackagesList = ({ data, userInfo }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { dataFlowdetail } = useSelector((state) => state.dataFlow);

  const { showSuccessMessage, showErrorMessage } = useContext(MessageContext);

  // added for CDASPRD-65 reversal
  const [expandedRows, setExpandedRows] = useState([]);
  const { selectedCard } = useSelector((state) => state.dashboard);

  // CDASPRD-65 removal - comment following
  const [tableData, setTableData] = useState([]);
  // CDASPRD-65 removal - uncomment following
  // const { selectedCard, pkglistExpanded } = useSelector(
  //   (state) => state.dashboard
  // );

  const { versionFreezed, isPublishedFlow } = useSelector(
    (state) => state.dataFlow
  );
  const { prot_id: protId } = selectedCard;

  const { canUpdate: canUpdateDataFlow } = useStudyPermission(
    Categories.CONFIGURATION,
    Features.DATA_FLOW_CONFIGURATION,
    protId
  );

  useEffect(() => {
    dispatch(updateDSStatus(false));
    // dispatch(setExpanded([])); // remove this line if u want to preserve the state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addDataSet = (
    dfId,
    dfName,
    dpId,
    dpName,
    dsId = null,
    dsName = "",
    path = null
  ) => {
    dispatch(
      redirectToDataSet(dfId, dfName, dpId, dpName, dsId, dsName, null, path)
    );
    dispatch(updateDSState(true));
    dispatch(updateDSStatus(false));
    history.push("/dashboard/dataset/new");
  };
  const TABULAR_VEEVA_OR_SOD = ["TabularRaveSOD", "TabularVeeva"];

  const DataSetsCell = ({ row, column: { accessor } }) => {
    const datasets = row[accessor] || row.datasets;
    return (
      <div className="flex flex-center dataset-count-td">
        <Typography variant="caption" className="datasetCount">
          {datasets?.length || 0}
        </Typography>
        {!isPublishedFlow &&
          !TABULAR_VEEVA_OR_SOD.includes(dataFlowdetail?.type) && (
            <span className="add-dataset">
              <Tooltip title="Add dataset" disableFocusListener>
                <RoundPlusSvg
                  disabled={!canUpdateDataFlow}
                  className="add-dataset-btn"
                  onClick={() =>
                    canUpdateDataFlow &&
                    addDataSet(
                      row.dataflowid,
                      "",
                      row.datapackageid,
                      row.name,
                      "",
                      "",
                      row.path
                    )
                  }
                />
              </Tooltip>
            </span>
          )}
      </div>
    );
  };

  const goToDataSet = (
    dfId,
    dfName,
    dpId,
    dpName,
    dsId,
    dsName,
    path = null
  ) => {
    // return if user is on same dataset
    if (params?.datasetId && dsId && params?.datasetId === dsId) {
      return;
    }
    dispatch(resetSqlColumns());
    dispatch(
      redirectToDataSet(dfId, dfName, dpId, dpName, dsId, dsName, null, path)
    );
    dispatch(updateDSState(false));
    setTimeout(() => {
      history.push(`/dashboard/dataset/${dsId}`);
    }, 500);
  };

  const DetailRow = ({ row }) => {
    // if (dataFlowdetail?.type === BASIC_DATA_CONNECTOR_DA) {
    //   console.log(">>>> d", row);
    //   row = {
    //     ...row,
    //     datasets: [
    //       {
    //         // ...x.datasets[0],
    //         datasetid: "abcd",
    //         name: "asdfddd.zip",
    //         nopackageconfig: 0,
    //         password: "",
    //         path: "",
    //         sod_view_type: null,
    //         type: "",
    //         updt_tm: "",
    //       },
    //     ],
    //   };
    // }
    return (
      <div className="datasets-list">
        {row.datasets?.map((dataset) => {
          return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
              className="dataset-row flex"
              key={dataset.datasetid}
              role="button"
              tabIndex={0}
              onClick={() => {
                if (
                  row.sod_view_type === null &&
                  dataFlowdetail?.type !== "TabularVeeva" &&
                  dataFlowdetail?.type !== BASIC_DATA_CONNECTOR_DA
                ) {
                  goToDataSet(
                    row.dataflowid,
                    "",
                    row.datapackageid,
                    row.name,
                    dataset.datasetid,
                    dataset.mnemonic,
                    row.path
                  );
                }
              }}
            >
              <div className="dataset-details">
                <Typography
                  variant="caption"
                  className={
                    TABULAR_VEEVA_OR_SOD.includes(dataFlowdetail?.type)
                      ? "sod-datasetName"
                      : "dataset-name"
                  }
                >
                  {dataset.name?.toUpperCase() ||
                    dataset.mnemonic ||
                    "DataSet Name"}
                </Typography>
                <Typography variant="caption" className="dataset-filetype">
                  {dataset.type?.toUpperCase() || "FileType"}
                </Typography>
              </div>
              <Status
                variant="positive"
                label={dataset.active ? "Active" : "Inactive"}
                size="small"
                className={`datasetStatus ${
                  dataset.active ? "active" : "inactive"
                }`}
                icon={StatusDotSolid}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const ActionCell = ({ row }) => {
    const active = row.active && Number(row.active) === 1 ? 1 : 0;
    const packageId = row.datapackageid || null;

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    // return incase of published flow
    if (isPublishedFlow) {
      return <></>;
    }

    const handleRequestClose = () => {
      setOpen(false);
    };

    const setActive = async (status) => {
      if (packageId) {
        let result = null;
        if (isPublishedFlow) {
          result = await updatePackageStatus({
            package_id: packageId,
            active: status === 1 ? "0" : "1",
            user_id: userInfo.userId,
            versionFreezed,
          });
        } else {
          result = await updateDraftPackageStatus({
            package_id: packageId,
            active: status === 1 ? "0" : "1",
            user_id: userInfo.userId,
            versionFreezed,
          });
        }
        if (result.error) {
          setTimeout(() => {
            showErrorMessage(result.error);
          }, 1000);
          return;
        }
        showSuccessMessage(result.message || "Deleted succefully");
        dispatch(updatePanel());
      }
    };

    const toggleDatasetActive = async (status) => {
      let result = null;
      if (isPublishedFlow) {
        result = await toggleDatasetsStatus({
          packageId,
          active: status ? 1 : 0,
          userId: userInfo.userId,
          versionFreezed,
        });
      } else {
        result = await toggleDraftDatasetsStatus({
          packageId,
          active: status ? 1 : 0,
          userId: userInfo.userId,
          versionFreezed,
        });
      }

      if (result.error) {
        showErrorMessage(result.error);
        return;
      }

      showSuccessMessage(
        result.message ||
          `All Datasets marked ${status ? "Active" : "Inactive"}`
      );
      dispatch(updatePanel());

      // logic for updating header count
      let localActiveCount = 0;
      let localInActiveCount = 0;

      // get active count from current dataset
      row?.datasets?.forEach((ds) => {
        if (ds?.active && !status) {
          localActiveCount += 1;
        }
        if (!ds?.active && status) {
          localInActiveCount += 1;
        }
      });

      let ActiveDsCountLocal = selectedCard?.ActiveDsCount;
      let InActiveDsCountLocal = selectedCard?.InActiveDsCount;
      if (!status) {
        ActiveDsCountLocal =
          parseInt(selectedCard?.ActiveDsCount, 10) -
          parseInt(localActiveCount || 0, 10);
        InActiveDsCountLocal =
          parseInt(selectedCard?.InActiveDsCount, 10) +
          parseInt(localActiveCount || 0, 10);
      }
      if (status) {
        ActiveDsCountLocal =
          parseInt(selectedCard?.ActiveDsCount, 10) +
          parseInt(localInActiveCount || 0, 10);
        InActiveDsCountLocal =
          parseInt(selectedCard?.InActiveDsCount, 10) -
          parseInt(localInActiveCount || 0, 10);
      }
      await dispatch(
        updateHeaderCount({
          dfCount: parseInt(selectedCard?.dfCount, 10),
          dsCount: parseInt(selectedCard?.dsCount, 10),
          ActiveDfCount: parseInt(selectedCard?.ActiveDfCount, 10),
          InActiveDfCount: parseInt(selectedCard?.InActiveDfCount, 10),
          ActiveDsCount: ActiveDsCountLocal,
          InActiveDsCount: InActiveDsCountLocal,
        })
      );
    };

    const deleteAction = async () => {
      if (packageId) {
        let result = null;
        if (isPublishedFlow) {
          result = await deletePackage({
            package_id: packageId,
            user_id: userInfo.userId,
            versionFreezed,
            delete_package: true,
          });
        } else {
          result = await deleteDraftPackage({
            package_id: packageId,
            delete_package: true,
            user_id: userInfo.userId,
          });
        }
        if (result.error) {
          showErrorMessage(result.error);
          return;
        }
        showSuccessMessage(result.message || "Deleted succefully");
        if (dataFlowdetail?.dataflowid) {
          dispatch(
            getPackagesList(dataFlowdetail?.dataflowid, "", !isPublishedFlow)
          );
        } else {
          dispatch(updatePanel());
        }
      }
    };

    const editAction = () => {
      if (packageId) {
        dispatch(selectDataPackage(row));
        history.push("/dashboard/data-packages");
      }
    };

    const menuItems =
      dataFlowdetail?.type === BASIC_DATA_CONNECTOR_DA
        ? [
            {
              text: `Set Config ${active === 1 ? "inactive" : "active"}`,
              onClick: () => setActive(active),
              disabled: !canUpdateDataFlow,
            },
          ]
        : [
            {
              text: `Set data package ${active === 1 ? "inactive" : "active"}`,
              onClick: () => setActive(active),
              disabled: !canUpdateDataFlow,
            },
            {
              text: "Set all datasets to active",
              onClick: () => toggleDatasetActive(true),
              disabled: !canUpdateDataFlow,
            },
            {
              text: "Set all datasets to inactive",
              onClick: () => toggleDatasetActive(),
              disabled: !canUpdateDataFlow,
            },
          ];

    if (!isPublishedFlow) {
      menuItems.push({
        text: "Delete data package",
        onClick: deleteAction,
        disabled: !canUpdateDataFlow,
      });
    }

    const openAction = (e) => {
      setAnchorEl(e.currentTarget);
      setOpen(true);
    };

    return (
      <div className="flex">
        <EllipsisVertical
          fontSize="small"
          onClick={openAction}
          style={{ cursor: "pointer" }}
        />
        {!TABULAR_VEEVA_OR_SOD.includes(dataFlowdetail?.type) ? (
          <Menu
            id="tableMenu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleRequestClose}
          >
            {menuItems.map((menu) => {
              return (
                <MenuItem
                  key={menu.text}
                  size="small"
                  disabled={menu.disabled}
                  onClick={menu.onClick}
                >
                  {menu.text}
                </MenuItem>
              );
            })}
          </Menu>
        ) : (
          <Menu
            id="tableMenu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleRequestClose}
          >
            <MenuItem size="small" onClick={editAction}>
              Edit data package
            </MenuItem>
          </Menu>
        )}
      </div>
    );
  };

  const columns = [
    {
      accessor: "expand",
      customCell: ExpandCell,
      width: 20,
    },
    {
      header: "Package Name",
      accessor: "name",
      customCell: NameCustomCell,
    },
    {
      header: "Datasets",
      accessor: "datasets",
      customCell: DataSetsCell,
    },
    {
      header: "Active",
      accessor: "active",
      customCell: StatusCustomCell,
      width: 80,
    },
    {
      accessor: "action",
      customCell: ActionCell,
      align: "right",
      width: 32,
    },
  ];

  const bdcColumns = [
    {
      accessor: "expand",
      customCell: ExpandCell,
      width: 20,
    },
    {
      header: "Package Name",
      accessor: "name",
      customCell: NameCustomCell,
    },

    {
      header: "Active",
      accessor: "active",
      customCell: StatusCustomCell,
      width: 80,
    },
    {
      accessor: "action",
      customCell: ActionCell,
      align: "right",
      width: 32,
    },
  ];

  const handleToggleRow = (datapackageid) => {
    // CDASPRD-65 removal - remove following
    // eslint-disable-next-line no-shadow
    setExpandedRows((expandedRows) =>
      expandedRows.includes(datapackageid)
        ? expandedRows.filter((id) => id !== datapackageid)
        : [...expandedRows, datapackageid]
    );

    // CDASPRD-65 removal - uncomment following
    // if (!Array.isArray(pkglistExpanded) && pkglistExpanded.length === 0)
    //   dispatch(setExpanded([datapackageid]));
    // else {
    //   dispatch(
    //     setExpanded(
    //       pkglistExpanded.includes(datapackageid)
    //         ? pkglistExpanded.filter((id) => id !== datapackageid)
    //         : [...pkglistExpanded, datapackageid]
    //     )
    //   );
    // }
  };

  useEffect(() => {
    const packagesList = data.packagesList || [];
    console.log(">>> packagelist", packagesList);
    setTableData(packagesList);
  }, [data.packagesList]);

  // CDASPRD-65 removal - remove following
  const dataSets = useSelector((state) => state.dataSets);
  const { selectedDataset } = dataSets;

  useEffect(() => {
    handleToggleRow(selectedDataset.datapackageid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.keys(selectedDataset).length]);
  // end

  return (
    <div className="remove-table-border-bottom">
      <Table
        columns={
          dataFlowdetail?.type === BASIC_DATA_CONNECTOR_DA
            ? bdcColumns
            : columns
        }
        rowId="packageName"
        rows={tableData.map((row) => ({
          ...row,
          // CDASPRD-65 removal - remove following
          expanded: expandedRows.includes(row.datapackageid),
          // CDASPRD-65 removal - uncomment following
          //    expanded: pkglistExpanded?.includes(row.datapackageid),
          handleToggleRow,
        }))}
        rowProps={{ hover: false }}
        hidePagination={true}
        ExpandableComponent={DetailRow}
      />
    </div>
  );
};
export default PackagesList;
