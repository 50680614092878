import AxiosInstance from "../../../services/AxiosInstance";
import {
  ADD_BDC_PACKAGE_DRAFT,
  DATAKINDAPI,
  PREVIEWSQLAPI,
  SQLTABLESAPI,
  SQLCOLUMNSAPI,
  baseURL,
} from "../../../constants";

export const saveBDCDraftDataPackage = async (reqBody) =>
  AxiosInstance.post(`${baseURL}/${ADD_BDC_PACKAGE_DRAFT}`, reqBody);

export const fetchClinicalDataType = async (param) =>
  AxiosInstance.get(`${baseURL}/${DATAKINDAPI}/list?${param}`);

export const fetchLocation = async (locationId) =>
  AxiosInstance.get(`${baseURL}/v1/api/location/detail/${locationId}`);

export const fetchPreviewSQL = async (reqBody) =>
  AxiosInstance.post(`${baseURL}/${PREVIEWSQLAPI}`, reqBody);

export const fetchSQLTables = async (reqBody) =>
  AxiosInstance.post(`${baseURL}/${SQLTABLESAPI}`, reqBody);

export const fetchTableColumns = async (reqBody) =>
  AxiosInstance.post(`${baseURL}/${SQLCOLUMNSAPI}`, reqBody);

export default {
  fetchPreviewSQL,
  saveBDCDraftDataPackage,
  fetchClinicalDataType,
  fetchSQLTables,
};
