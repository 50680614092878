/* eslint-disable prefer-destructuring */
/* eslint-disable eqeqeq */
// libraries
import React from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { isEmpty, isEqual } from "lodash";
// apollo components
import Autocomplete from "apollo-react/components/Autocomplete";
import DateRangePicker from "apollo-react/components/DateRangePicker";
import { TextField } from "apollo-react/components/TextField/TextField";
// helpers
import { DATA_TYPES, IDLE_LOGOUT_TIME, publishSteps } from "./constants";
import {
  checkAlphaNumericFileName,
  isVlcTildSaparated,
} from "../components/FormComponents/validators";
import {
  ClinicalOneODMIdentifier,
  InformODMIdentifier,
  RaveODMIdentifier,
} from "../constants";
import { resetPreviousTableFilters } from "../store/actions/DashboardAction";

const momentTimeFormat = "DD-MMM-YYYY hh:mm A";

const useFilterTable = () => {
  const dispatch = useDispatch();

  const resetMonitorAndDataflowTableFilters = () => {
    dispatch(resetPreviousTableFilters("monitorTable"));
    dispatch(resetPreviousTableFilters("dataflowTable"));
  };

  const getTableNameForFilterCaching = (path, tabValue) => {
    let tableName = "";
    switch (path) {
      case "/dashboard":
        tableName = tabValue ? "dataflowTable" : "monitorTable";
        break;
      case "/dashboard/monitor":
        tableName = "monitorViewAllTable";
        break;
      case "/cdihome":
        tableName = "cdiHomeMonitorTable";
        break;
      case "/cdihome/monitor":
        tableName = "cdiHomeViewAllTable";
        break;
      default:
        tableName = tabValue ? "dataflowTable" : "monitorTable";
        break;
    }

    return tableName;
  };

  return { getTableNameForFilterCaching, resetMonitorAndDataflowTableFilters };
};

export default useFilterTable;

// Retrives extension in lowercase from a file name
export const getExtension = (name) =>
  name ? name.split(".").pop()?.toLowerCase() : "";

export const getCookie = (key) => {
  const b = document.cookie.match(`(^|;)\\s*${key}\\s*=\\s*([^;]+)`);
  return b ? b.pop() : "";
};

export const isSftp = (str = "") => {
  return ["SFTP", "FTPS", "AMAZON S3"].includes(str?.trim()?.toUpperCase());
};

export const isOdm = (str = "") =>
  [
    ClinicalOneODMIdentifier.toUpperCase(),
    InformODMIdentifier.toUpperCase(),
    RaveODMIdentifier.toUpperCase(),
  ].includes(str?.trim()?.toUpperCase());

export const isAmazonS3 = (str = "") => {
  return ["AMAZON S3"].includes(str?.trim()?.toUpperCase());
};

export const isSftpOrAmazonS3 = (str = "") => isSftp(str) || isAmazonS3(str);

export const secondsToHms = (d) => {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);
  return `${h}h ${m}m ${s}s`;
};

// URL Related
export function getQueryParams(query) {
  const queryStrings = query.substr(1).split("&");
  const queryParams = {};
  queryStrings.forEach((element) => {
    const keyAndValue = element.split("=");
    // eslint-disable-next-line prefer-destructuring
    queryParams[keyAndValue[0]] = keyAndValue[1];
  });
  return queryParams;
}

export function getPathnameAndSearch(path) {
  const arr = path.split("?");
  return {
    pathname: arr[0],
    search: `?${arr[1]}`,
  };
}

export const getHeaderValue = (accessor) => {
  switch (accessor) {
    case "protocolnumber":
      return "Protocol Number";
    case "sponsorname":
      return "Sponsor Name";
    case "phase":
      return "Phase";
    case "protocolstatus":
      return "Protocol Status";
    case "dateadded":
      return "Date Added";
    case "dateedited":
      return "Date Edited";
    case "onboardingprogress":
      return "Onboarding Progress";
    case "assignmentcount":
      return "Assignment Count";
    case "therapeuticarea":
      return "Therapeutic Area";
    case "projectcode":
      return "Project Code";
    default:
      return "";
  }
};

export function getLastLogin() {
  const currentLogin = getCookie("user.last_login_ts");
  if (!currentLogin || currentLogin === "first_time") return null;
  return moment.utc(moment.unix(currentLogin)).local().format(momentTimeFormat);
}

export const getDomainName = () => {
  const urlParts = window.location.hostname.split(".");
  return urlParts
    .slice(0)
    .slice(-(urlParts.length === 4 ? 3 : 2))
    .join(".");
};

export function deleteAllCookies() {
  const domain = getDomainName() || "";
  document.cookie.split(";").forEach((c) => {
    document.cookie = c // NOSONAR
      .replace(/^ +/, "")
      .replace(/=.*/, `=;expires=${new Date().toUTCString()};domain=${domain}`);
  });
  return true;
}

export function getUserId(preventRedirect) {
  const userId = getCookie("user.id");
  if (!userId && !preventRedirect) {
    window.location.reload();
  }
  return userId;
}

export function getTenantId() {
  try {
    return getCookie("tenant") || "";
  } catch (e) {
    return "";
  }
}

export function getUserInfo() {
  return {
    fullName: decodeURIComponent(`${getCookie("user.first_name")} 
                                  ${getCookie("user.last_name")}`),
    firstName: getCookie("user.first_name"),
    lastName: getCookie("user.last_name"),
    userEmail: decodeURIComponent(getCookie("user.email")),
    lastLogin: getLastLogin(),
    userId: getUserId(),
  };
}

let timerId;
export const debounceFunction = (func, delay) => {
  // Cancels the setTimeout method execution
  clearTimeout(timerId);
  // Executes the func after delay time.
  timerId = setTimeout(func, delay);
};

export const titleCase = (str) => {
  const splitStr = str.toLowerCase().split(" ");
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

export const compareStrings = (accessor, sortOrder) => {
  return (rowA, rowB) => {
    if (!rowA[accessor]) {
      return 1;
    }
    if (!rowB[accessor]) {
      return -1;
    }
    const stringA = rowA[accessor].toString().toUpperCase();
    const stringB = rowB[accessor].toString().toUpperCase();
    if (sortOrder === "asc") {
      if (stringA < stringB) {
        return -1;
      }

      if (stringA > stringB) {
        return 1;
      }

      return 0;
    }
    if (stringA < stringB) {
      return 1;
    }

    if (stringA > stringB) {
      return -1;
    }

    return 0;
  };
};

export const compareNumbers = (accessor, sortOrder) => {
  return (rowA, rowB) => {
    if (!rowA[accessor]) {
      return 1;
    }
    if (!rowB[accessor]) {
      return -1;
    }
    const numberA = rowA[accessor];
    const numberB = rowB[accessor];

    if (sortOrder === "asc") {
      return numberA - numberB;
    }
    return numberB - numberA;
  };
};

/* eslint-disable react/display-name */
export const compareDates = (accessor, sortOrder) => {
  return (rowA, rowB) => {
    const dateA = rowA[accessor];
    const dateB = rowB[accessor];

    if (sortOrder === "asc") {
      if (moment(dateA).isBefore(dateB)) {
        return -1;
      }
      if (moment(dateB).isBefore(dateA)) {
        return 1;
      }
      return 0;
    }
    if (moment(dateA).isBefore(dateB)) {
      return 1;
    }
    if (moment(dateB).isBefore(dateA)) {
      return -1;
    }
    return 0;
  };
};

// eslint-disable-next-line consistent-return
export const inputAlphaNumericWithUnderScore = (e, callback) => {
  const value = e.target.value
    ? e.target.value.replace(/[^0-9a-zA-Z_]+/gi, "")
    : "";

  if (e.target.value !== value) {
    e.target.value = value;
  }

  if (typeof callback === "function") {
    return callback(value);
  }
};

export const createAutocompleteFilter =
  (source) =>
  ({ accessor, filters, updateFilterValue }) => {
    const ref = React.useRef();
    const [height, setHeight] = React.useState(0);
    const [isFocused, setIsFocused] = React.useState(false);
    const value = filters[accessor];

    React.useEffect(() => {
      const curHeight = ref?.current?.getBoundingClientRect().height;
      if (curHeight !== height) {
        setHeight(curHeight);
      }
    }, [value, isFocused, height]);

    return (
      <div
        style={{
          minWidth: 160,
          maxWidth: "100%",
          position: "relative",
          height,
        }}
      >
        <Autocomplete
          style={{ position: "absolute", left: 0, right: 0 }}
          value={
            value
              ? value.map((label) => {
                  if (!label && accessor === "version_nm") {
                    return { label: "Draft" };
                  }
                  if (label === "") {
                    return { label: "blanks" };
                  }
                  return { label };
                })
              : []
          }
          name={accessor}
          source={source}
          onChange={(event, value2) => {
            updateFilterValue({
              target: {
                name: accessor,
                value: value2.map(({ label }) => {
                  if (label === "Draft") return null;
                  if (label === "blanks") {
                    return "";
                  }
                  return label;
                }),
              },
            });
          }}
          fullWidth
          multiple
          size="small"
          limitChips={1}
          filterSelectedOptions={false}
          enableVirtualization
          clearOnBlur={false}
          matchFrom="any"
          showSelectAll
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          ref={ref}
          noOptionsText="No matches"
        />
      </div>
    );
  };

export const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      fullWidth
      margin="none"
      size="small"
    />
  );
};

export const IntegerFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      type="number"
      style={{ width: 74 }}
      margin="none"
      size="small"
    />
  );
};

export const DateFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <div style={{ minWidth: 230 }}>
      <div style={{ position: "absolute", top: 0, paddingRight: 4 }}>
        <DateRangePicker
          value={filters[accessor] || [null, null]}
          name={accessor}
          onChange={(value) =>
            updateFilterValue({
              target: { name: accessor, value },
            })
          }
          startLabel=""
          endLabel=""
          placeholder=""
          fullWidth
          margin="none"
          size="small"
        />
      </div>
    </div>
  );
};

export const createStringArraySearchFilter = (accessor) => {
  return (row, filters) =>
    !Array.isArray(filters[accessor]) ||
    filters[accessor].length === 0 ||
    filters[accessor].some(
      (value) => value.toUpperCase() === row[accessor]?.toUpperCase()
    );
};

export const createStringArraySearchFilterDraft = (accessor) => {
  if (accessor === "version_nm") {
    return (row, filters) =>
      !Array.isArray(filters[accessor]) ||
      filters[accessor].length === 0 ||
      filters[accessor].some((value) => {
        if (value?.toLowerCase?.() === "blank") {
          return (
            row?.publish_status &&
            row?.publish_status?.toLowerCase() !== "draft" &&
            !row?.version_nm
          );
        }
        if (value?.toLowerCase?.() === "draft" || !value) {
          return (
            value?.toUpperCase?.() === row[accessor]?.toUpperCase?.() &&
            (!row?.publish_status ||
              row?.publish_status?.toLowerCase() === "draft") &&
            !row?.version_nm
          );
        }
        return value?.toUpperCase?.() === row[accessor]?.toUpperCase?.();
      });
  }
  if (accessor === "adapter") {
    return (row, filters) =>
      !Array.isArray(filters[accessor]) ||
      filters[accessor].length === 0 ||
      filters[accessor].some((value) => {
        if (value?.toLowerCase?.() === "blank") {
          return !row?.adapter;
        }
        return value?.toUpperCase?.() === row[accessor]?.toUpperCase?.();
      });
  }
  return (row, filters) =>
    !Array.isArray(filters[accessor]) ||
    filters[accessor].length === 0 ||
    filters[accessor].some(
      (value) => value?.toUpperCase?.() === row[accessor]?.toUpperCase?.()
    );
};

export const createStatusArraySearchFilter = (accessor) => {
  return (row, filters) => {
    return (
      !Array.isArray(filters[accessor]) ||
      filters[accessor].length === 0 ||
      filters[accessor].some((value) => {
        if (value === "Inactive" || value === 0) {
          return row[accessor] === 0 || row[accessor] === "inactive";
        }
        if (value === "Active" || value === 1) {
          return row[accessor] === 1 || row[accessor] === "active";
        }
        return false;
      })
    );
  };
};
export const toast = (text = "", type = "success") => {
  const customEvent = new CustomEvent("toast", { detail: { text, type } });
  document.dispatchEvent(customEvent);
};

export const columnObj = {
  variableLabel: "",
  columnName: "",
  position: "",
  format: "",
  dataType: "",
  primaryKey: "No",
  unique: "No",
  required: "No",
  minLength: "",
  maxLength: "",
  values: "",
  isInitLoad: true,
  isFormatLoad: true,
  isHavingColumnName: false,
  isEditMode: true,
};

export const getInitColumnObj = () => {
  return { ...columnObj };
};

export const checkHeaders = (data) => {
  const header = data[0];
  const validation =
    header.includes("Protocol") &&
    header.includes("Variable Label") &&
    header.includes("Column Name/Designator") &&
    header.includes("Format") &&
    header.includes("Data Type") &&
    header.includes("Primary(Y/N)") &&
    header.includes("Unique(Y/N)") &&
    header.includes("Required(Y/N)") &&
    header.includes("Min length") &&
    header.includes("Max length") &&
    header.includes("List of values");
  return validation;
};

export const setYN = (d) => (d === "Y" ? "Yes" : "No");

export const formatDataNew = (incomingData, protNo) => {
  const data = incomingData.slice(1); // removing header
  let isAllDataMatch = false;
  if (data.length === 1) {
    isAllDataMatch = data[0][0].toString() === protNo.toString();
  } else if (data.length > 1) {
    isAllDataMatch = data
      .map((e) => e[0])
      .every((ele) => ele?.toString() === protNo.toString()); // checking for protocol match
  }
  if (isAllDataMatch) {
    const newData =
      data.length > 0
        ? data.map((e, i) => {
            const newObj = {
              uniqueId: i + 1,
              variableLabel: e[1] || "",
              columnName: e[2] || "",
              position: "",
              format: e[3] || "",
              dataType: e[4] || "",
              primaryKey: setYN(e[5]),
              unique: setYN(e[6]),
              required: setYN(e[7]),
              minLength: e[8] || e[8] === 0 ? e[8] : "",
              maxLength: e[9] || e[9] === 0 ? e[9] : "",
              values: e[10] || "",
              isInitLoad: false,
              isHavingColumnName: true,
              isEditMode: true,
            };
            return newObj;
          })
        : [];
    return { headerNotMatching: false, data: newData };
  }
  return { headerNotMatching: !isAllDataMatch, data: [] };
};

export const capitaliseString = (str = "") => {
  if (str.length > 1) {
    return str ? str[0].toUpperCase() + str.toLowerCase().substring(1) : "";
  }
  return str.toUpperCase();
};

export const formatData = (incomingData, protNo) => {
  const data = incomingData.slice(1); // removing header
  let isAllDataMatch = false;
  if (data.length === 1) {
    isAllDataMatch = data[0][0].toString() === protNo.toString();
  } else {
    isAllDataMatch = data
      .map((e) => e[0])
      .every((ele) => ele?.toString() === protNo.toString()); // checking for protocol match
  }

  if (isAllDataMatch) {
    const newData =
      data.length > 0
        ? data.map((e, i) => {
            const newObj = {
              uniqueId: `u${i}`,
              index: i,
              variableLabel: e[1] || "",
              columnName: e[2] || "",
              position: "",
              format: e[3] || "",
              dataType: capitaliseString(e[4]),
              primaryKey: setYN(e[5]),
              unique: setYN(e[6]),
              required: setYN(e[7]),
              minLength: e[8] || e[8] === 0 ? e[8] : "",
              maxLength: e[9] || e[9] === 0 ? e[9] : "",
              values: e[10] || "",
              isInitLoad: true,
              isHavingColumnName: true,
            };
            return newObj;
          })
        : [];
    return newData;
  }
  return [];
};

export const Capitalize = (str) => {
  return str && str.charAt(0).toUpperCase() + str.slice(1);
};

export const createSourceFromKey = (tableRows, key) => {
  return Array.from(
    new Set(
      tableRows
        ?.map((r) => ({ label: Capitalize(r[key]) }))
        .map((item) => item.label)
    )
  )
    .map((label) => {
      return { label: label || "" };
    })
    .sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
};

// constants for location - data types
const TABULAR_DT = "Tabular";
const TABULAR_RAVE_DT = "Tabular - Rave SOD";
const TABULAR_RAVE_DT_VALUE = "TabularRaveSOD";

export const dataStruct = [
  {
    value: TABULAR_DT,
    label: TABULAR_DT,
  },
  {
    value: TABULAR_RAVE_DT_VALUE,
    label: TABULAR_RAVE_DT,
  },
];

export const getDataStruct = {
  TabularRaveSOD: TABULAR_RAVE_DT,
  Tabular: TABULAR_DT,
};

export const extSysName = [
  {
    value: "",
    label: "None",
  },
  {
    value: "CDR",
    label: "CDR",
  },
  {
    value: "GDMPM-DAS",
    label: "GDMPM-DAS",
  },
  {
    value: "IQB",
    label: "IQB",
  },
  {
    value: "TDSE",
    label: "TDSE",
  },
  {
    value: "Wingspan",
    label: "Wingspan",
  },
];

export const locationTypes = [
  "Amazon S3",
  "SFTP",
  "FTPS",
  "Hive CDP",
  "Hive CDH",
  "Impala",
  "MySQL",
  "Oracle",
  "PostgreSQL",
  "SQL Server",
  // "SQL Server Dynamic Port", Don't remove. This need to use in Portless connection
];

export const SodLocationTypes = ["SFTP", "FTPS", "Amazon S3"];
export const VeevaLocationTypes = ["SFTP", "FTPS"];
export const ODMLocationTypes = ["ODM"];
export const fileTypes = ["SAS", "Excel", "Delimited", "Fixed Width"];
export const BDCSupportedExtensions = [
  "csv",
  "txt",
  "xls",
  "xlsx",
  "xpt",
  "zip",
  "7z",
  "sas7bdat",
  "rar",
];

export const fileTypesBasic = ["Excel", "Delimited", "SAS"];
export const fileTypesSf = ["Delimited", "Fixed Width"];
export const delimiters = ["COMMA", "TAB", "TILDE", "PIPE"];
export const loadTypes = ["Cumulative", "Incremental"];
export const YesNo = ["Yes", "No"];
export const offsetColumnDataTypes = ["Numeric", "Date"];

export const verifyBDCSupportedExtensions = (fileName) =>
  BDCSupportedExtensions.includes(getExtension(fileName));

export const removeExtension = (filename) =>
  filename?.substring(0, filename?.lastIndexOf(".")) || filename;

export const removeAngular = (name) => name.replace(/<.*?>/g, ""); // NOSONAR
export const removeSpecial = (name) => name.replace(/([^A-Za-z0-9])/g, "");

export const truncateString = (str, length) => {
  if (str) {
    const ending = "...";
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    }
    return str;
  }
  return "";
};

export const generateConnectionURL = (
  locType,
  hostName,
  port,
  dbName,
  whse = "",
  schem = ""
) => {
  if (!locType || !hostName) {
    return "";
  }

  // SFTP
  if (locType !== "" && (locType === "SFTP" || locType === "FTPS")) {
    return hostName;
  }

  // Hive
  if (locType === "Hive CDP" || locType === "Hive CDH") {
    const transportMode = locType === "Hive CDP" ? "http" : "https";
    return port && dbName
      ? `jdbc:hive2://${hostName}:${port}/${dbName};transportMode=${transportMode};httpPath=cliservice;ssl=1;AllowSelfSignedCerts=1;AuthMech=3`
      : "";
  }

  // Oracle
  if (locType === "Oracle") {
    return port && dbName
      ? `jdbc:oracle:thin:@${hostName}:${port}:${dbName}`
      : "";
  }

  // MySQL
  if (locType === "MySQL") {
    return dbName && port ? `jdbc:mysql://${hostName}:${port}/${dbName}` : "";
  }

  // SQL Server
  if (locType === "SQL Server") {
    return dbName && port
      ? `jdbc:sqlserver://${hostName}:${port};databaseName=${dbName}`
      : "";
  }
  if (locType === "SQL Server Dynamic Port") {
    return dbName ? `jdbc:sqlserver://${hostName};databaseName=${dbName}` : "";
  }

  // PostgreSQL
  if (locType === "PostgreSQL") {
    return port && dbName
      ? `jdbc:postgresql://${hostName}:${port}/${dbName}`
      : "";
  }

  // Impala
  if (locType === "Impala") {
    return port
      ? `jdbc:impala://${hostName}:${port}/${dbName};ssl=1;AllowSelfSignedCerts=1;AuthMech=3`
      : "";
  }

  // S3
  if (isAmazonS3(locType)) {
    return `https://${hostName}.s3.amazonaws.com`;
  }
  if (locType === "Azure – SQL Server") {
    return port && dbName
      ? `jdbc:sqlserver://${hostName}:${port};databaseName=${dbName}`
      : "";
  }
  if (locType === "Azure – Snowflake") {
    return port && dbName && whse && schem
      ? `jdbc:snowflake://${hostName}:${port}/?db=${dbName}&warehouse=${whse}&schema=${schem}`
      : "";
  }

  if (locType && hostName && port && dbName) {
    return `jdbc:${locType}://${hostName}:${port}/${dbName}`;
  }

  return "";
};

export const generatedBName = (locType) => {
  if (locType.includes("SQL Server")) {
    return "MSSQLSERVER";
  }
  if (locType === "Hive CDP" || locType === "Hive CDH") {
    return "HIVE";
  }
  return locType.toUpperCase();
};

export const matchAppUrl = () => {
  let appUrl = getCookie("user.app_url");
  if (appUrl) appUrl = decodeURIComponent(appUrl);
  return process.env?.REACT_APP_CORE_URL?.replace(/\/$/, "") === appUrl;
};

export const dateFilterCustom = (accessor) => (row, filters) => {
  if (!filters[accessor]) {
    return true;
  }
  if (!row[accessor]) {
    return false;
  }
  const date = moment(row[accessor]);

  const fromDate = moment(filters[accessor][0], "YYYY-MM-DD");

  const toDate = moment(filters[accessor][1], "YYYY-MM-DD").endOf("day");

  return (
    (!fromDate.isValid() || date.isAfter(fromDate)) &&
    (!toDate.isValid() || date.isBefore(toDate))
  );
};

export const validateFields = (name, ext) => {
  if (!name || !ext) return false;
  const fileExt = name.split(".").pop();
  if (ext?.toLowerCase() === "sas") ext = "xpt";
  if (ext?.toLowerCase() === fileExt.toLowerCase()) {
    return true;
  }
  return false;
};

export const validatePackageName = (name, isS3Location) => {
  if (!name) return false;
  return checkAlphaNumericFileName(name, "package", isS3Location);
};

export const goToCore = () => {
  if (process.env.REACT_APP_CORE_URL)
    window.location.href = process.env.REACT_APP_CORE_URL;
};

export const dateTypeForJDBC = (datatype) => {
  const type = datatype?.toUpperCase();
  if (DATA_TYPES.alphanumeric.includes(type)) {
    return "Alphanumeric";
  }
  if (DATA_TYPES.numeric.includes(type)) {
    return "Numeric";
  }
  if (DATA_TYPES.date.includes(type)) {
    return "Date";
  }
  return "Alphanumeric";
};
export const parseBool = (b) => {
  return !/^(false|0)$/i.test(b) && !!b;
};
export const setIdleLogout = (logout) => {
  let time;
  // DOM Events
  function resetTimer() {
    clearTimeout(time);
    time = setTimeout(() => {
      logout();
    }, IDLE_LOGOUT_TIME);
  }
  document.onmousemove = resetTimer;
  document.onkeypress = resetTimer;
  window.onload = resetTimer;
};

export const scrollIntoView = () => {
  const body = document.querySelector("#root");
  body.scrollIntoView(
    {
      behavior: "smooth",
    },
    1500
  );
};

export const extractHostname = (url) => {
  try {
    let hostname;
    // find & remove protocol (http, ftp, etc.) and get hostname
    if (url?.indexOf("//") > -1) {
      hostname = url.split("/")[2];
    } else {
      hostname = url.split("/")[0];
    }
    // find & remove port number
    hostname = hostname.split(":")[0];
    // find & remove "?"
    hostname = hostname.split("?")[0];
    return hostname;
  } catch {
    return url;
  }
};

export const convertLocalFormat = (time) => {
  return time
    ? moment.utc(time).local().format(momentTimeFormat)
    : moment().local().format(momentTimeFormat);
};

export const getAppUrl = (app) => {
  let appUrl;
  switch (app) {
    case "CDI":
      appUrl =
        process.env.REACT_APP_CDI_URL ||
        `${window.location.protocol}//${window.location.hostname}:3001`;
      break;

    case "CDM":
      appUrl =
        process.env.REACT_APP_CDM_URL ||
        `${window.location.protocol}//${window.location.hostname}:3000`;
      break;

    default:
      appUrl = `${window.location.protocol}//${window.location.hostname}:3000`;
      break;
  }
  // eslint-disable-next-line consistent-return
  return appUrl;
};

export const goToApp = (path) => {
  console.log(path);
  window.location.href = path;
};

export const checkFormChanges = (form, dataFlowStore) => {
  let isAnyChange = false;
  if (!isEmpty(form)) {
    Object.entries(form).forEach(([key, item]) => {
      if (key && item?.initial && item?.values && !isAnyChange) {
        if (!isEqual(item?.initial, item?.values)) {
          isAnyChange = true;
        }

        // check for location drop down changes
        if (key === "DataFlowForm" && !isAnyChange) {
          const reduxFormLocationValue =
            item?.values?.locations?.[0]?.value || null;
          const dataFlowStoreLocationValue =
            dataFlowStore?.selectedLocation?.src_loc_id || null;
          if (
            reduxFormLocationValue &&
            dataFlowStoreLocationValue &&
            reduxFormLocationValue !== dataFlowStoreLocationValue
          ) {
            isAnyChange = true;
          }
        }
      }
    });
  }
  return isAnyChange;
};

export const stringToBoolean = (string) => {
  switch (string?.toString().toLowerCase().trim()) {
    case "true":
    case "yes":
    case "y":
    case "1":
      return true;
    case "false":
    case "no":
    case "n":
    case "0":
    case null:
      return false;
    default:
      return false;
  }
};

// check if LOV is valid for input fields
export const checkLOVError = (input, returnBool = false) => {
  if (!returnBool) {
    return input && !isVlcTildSaparated(input)
      ? "LOV must be separated by a tilde “~”"
      : false;
  }
  return input && !isVlcTildSaparated(input) ? true : false;
};

// checks latest date and returns that object
export const getLatestDateOnData = (
  rowData,
  sendOnlyObj = false,
  checkForErrorOnly = false
) => {
  // case when we need to only check for proceed with errors
  if (checkForErrorOnly) {
    let latestDate =
      Math.max(
        ...rowData?.map(
          (ele) =>
            ele?.FileTransferStatus?.toLowerCase() ===
              "processed with errors" && new Date(ele?.TransferDate)
        )
      ) || null;
    if (latestDate) latestDate = new Date(latestDate);

    // get latest object of that date
    const latestObject =
      rowData?.filter((ele) => {
        const date = new Date(ele?.TransferDate);
        return (
          ele?.FileTransferStatus?.toLowerCase() === "processed with errors" &&
          date.getTime() === latestDate.getTime()
        );
      }) || null;

    return latestObject || null;
  }

  let arrayIndex = null;
  let latestDate =
    Math.max(...rowData?.map((ele) => new Date(ele?.TransferDate))) || null;
  if (latestDate) latestDate = new Date(latestDate);

  // get latest object of that date
  const latestObject =
    rowData?.filter((ele, index) => {
      const date = new Date(ele?.TransferDate);
      if (date?.getTime?.() === latestDate?.getTime?.()) {
        arrayIndex = index;
        return true;
      }
      return false;
    }) || null;

  if (sendOnlyObj) return latestObject;

  return { arrayIndex, latestObject };
};

export const getLatestDateFromIngestionData = (
  rowData,
  checkSuccess = false
) => {
  if (!rowData) return null;

  // check if first object and proceed
  if (checkSuccess) {
    const { latestObject } = getLatestDateOnData(rowData);

    // case when latest status if successful or failed
    if (
      latestObject?.[0]?.FileTransferStatus?.toLowerCase() === "successful" ||
      latestObject?.[0]?.FileTransferStatus?.toLowerCase() === "failed"
    ) {
      return true;
    }

    return false;
  }

  // get latest object of that date
  const latestObject = getLatestDateOnData(rowData, true, true);

  return latestObject?.[0]?.TransferDate || null;
};

/**
 * Checks for step and return title
 *
 * @param {number} step current step
 * @param {boolean} isContentTitle flag to check if it is for content title
 * @return {string} title
 */
export const getPublishTitle = (step, isContentTitle = false) => {
  if (isContentTitle) {
    switch (step) {
      case 1:
        return "Validation Result";
      case 2:
      case 3:
      case 4:
        return "New version details";
      default:
        return "Validation Result";
    }
  } else {
    switch (step) {
      case 1:
        return publishSteps[0]?.toString();
      case 2:
        return publishSteps[1]?.toString();
      case 3:
      case 4:
        return publishSteps[2]?.toString();
      default:
        return publishSteps[0]?.toString();
    }
  }
};

/**
 * Checks system name against external system name
 *
 * @param {string} system
 * @return {boolean}
 */
export const checkForExternalSystem = (system) => {
  if (system !== "CDI") {
    return true;
  }
  return false;
};

export const encryptionTypeOptions = [
  {
    label: "Explicit",
    value: "EXPLICIT",
  },
  {
    label: "Implicit",
    value: "IMPLICIT",
  },
];

export const defaultEncryptionType = {
  EXPLICIT: "EXPLICIT",
  IMPLICIT: "IMPLICIT",
};

export const getUniqueId = () => {
  const randomValues = new Uint32Array(4);
  const crypto = window.crypto || window.msCrypto;
  crypto.getRandomValues(randomValues);
  return Array.from(randomValues)
    .map((value) => value.toString(36))
    .join("")
    .slice(2, 13);
};

/**
 * Checks if location is Amazon S3
 *
 * @param {string} locType
 * @return {boolean}
 */
export const checkIfLocationIsS3 = (locType) => {
  try {
    const isLocationS3 = locType
      ? ["amazon s3"]?.includes(locType?.toString?.()?.toLowerCase?.())
      : false;
    return isLocationS3;
  } catch (e) {
    return false;
  }
};

export const getStudyIdentifier = (dataSource) => {
  switch (dataSource) {
    case "RaveODM":
      return {
        studyLabel: "Rave Study Name",
        envLabel: "Rave Study Environment",
      };
    case "ClinicalOneODM":
      return {
        studyLabel: "ClinicalOne Study Name",
        envLabel: "ClinicalOne Study Environment",
      };
    case "InformODM":
      return {
        studyLabel: "Inform Study Name",
        envLabel: "Inform Study Environment",
      };
    default:
      return {
        studyLabel: "EDC Study Identifier",
        envLabel: "EDC study environment",
      };
  }
};
/**
 * Returns breadcrumb item used on data flow view/edit page at top
 *
 * @return {Array}
 */
export const getDataFlowBreadCrumb = ({
  history,
  location = "DATAFLOW",
  dataflowId = null,
  dataFlowName = null,
  dataPackageName = null,
  dataSetName = null,
  goToDataflow = () => {},
  gotoDataPackage = () => {},
}) => {
  // eslint-disable-next-line no-script-url
  const voidJS = "javascript:void(0)";

  // group same items in one object
  const breadcrumbItemsLocal = [
    { href: voidJS, onClick: () => history.push("/dashboard") },
    {
      href: voidJS,
      title: "Monitor",
      onClick: () => history.push("/dashboard"),
    },
    {
      href: voidJS,
      title: "Data Flows",
      onClick: () =>
        history.push("/dashboard", {
          from: "dataflow-tab",
        }),
    },
  ];

  const breadcrumbItems = [...breadcrumbItemsLocal];

  // DATA FLOW Page
  if (location === "DATAFLOW") {
    if (dataflowId) {
      breadcrumbItems.push({
        href: voidJS,
        title: "Data Flow Settings",
        onClick: () => history.push(`/dataflow-management/${dataflowId}`),
      });
    } else {
      breadcrumbItems.push({
        href: voidJS,
        title: "New Data Flow",
        onClick: () => history.push(`/dataflow-management/${dataflowId}`),
      });
    }
  }

  // DATA PACKAGE Page
  if (location === "DATAPACKAGE") {
    breadcrumbItems.push(
      {
        href: voidJS,
        title: dataFlowName || "Data Flow Settings",
        onClick: goToDataflow,
      },
      {
        href: voidJS,
        title: dataPackageName || "Data Package Settings",
        onClick: () => history.push("/dashboard/data-packages"),
      }
    );
  }

  // DATA SET Page
  if (location === "DATASET") {
    breadcrumbItems.push(
      {
        href: voidJS,
        title: dataFlowName ?? "Data Flow Settings",
        onClick: goToDataflow,
      },
      {
        href: voidJS,
        title: dataPackageName || "Data Package",
        onClick: gotoDataPackage,
      },
      {
        href: "#",
        title: dataSetName || "New Dataset",
      }
    );
  }

  return breadcrumbItems;
};

export const leftPanelUseStyles = makeStyles(() => ({
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: 25,
    maxHeight: "70px",
    justifyContent: "space-between",
  },
  leftPanel: {
    display: "inline-flex",
    alignItems: "center",
    color: "#595959",
  },
  LeftTitle: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "24px",
    marginTop: 12,
  },
  LeftSubTitle: {
    fontSize: 18,
    color: "#444444",
    lineHeight: "20px",
    marginTop: 4,
  },
  description: {
    color: "#444444",
    fontFamily: "Proxima Nova",
    fontSize: "14px",
    letterSpacing: 0,
    display: "flex",
    alignItems: "center",
    lineHeight: "24px",
    marginTop: 23,
  },
  dataflowLeft: {
    width: 16,
    height: 16,
  },
}));

export const dataFlowJDBCFormStyles = {
  paper: {
    padding: "25px 16px",
  },
  submit: {
    margin: "16px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  section: {
    marginBottom: 32,
  },
  subsection: {
    marginBottom: 8,
  },
  divider: {
    marginBottom: 24,
  },
  locationBox: {
    boxSizing: "border-box",
    border: "1px solid #E9E9E9",
    borderRadius: 4,
    backgroundColor: "#FFFFFF",
    padding: "10px 15px",
  },
  formLabel: {
    color: "#444444",
    fontSize: 14,
    marginTop: "15px",
    letterSpacing: 0,
    lineHeight: "24px",
  },
  formText: {
    color: "#000000",
    fontSize: 14,
    marginTop: 8,
    marginLeft: 5,
    letterSpacing: 0,
    lineHeight: "24px",
  },
  formPass: {
    color: "#000000",
    fontSize: 30,
    marginTop: 8,
    marginLeft: 5,
    textSecurity: "disc",
    "-webkit-text-security": "disc",
    "-moz-text-security": "disc",
    letterSpacing: 5,
    lineHeight: "24px",
  },
};

export const isArrayEmptyObjects = (arr) => {
  // Check for empty array first
  if (arr.length === 0) {
    return true;
  }

  // Efficiently check if every object is empty using every()
  return arr.every((obj) => Object.keys(obj).length === 0);
};
